import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";

const actions = {
  wallet: () => {
    return http.post(`${getApiUrl()}/api/yop/wallet`);
  },
};

export default {
  ...actions,
};
