import NavBar from "@/components/NavBar";
import yopModel from "@/models/yop.model";
import { Button, Image, Popup, Toast } from "react-vant";
import styles from "./index.module.css";
import { useReactive } from "ahooks";
import { useSnapshot } from "valtio";
import globalModel from "@/models/global.model";

export default () => {
  const { user } = useSnapshot(globalModel.state);
  const state = useReactive({
    url: null,
  });
  return (
    <div className={styles.page}>
      <NavBar title="我的钱包" />
      <div
        className={`${styles.item} ${styles.wallet1}`}
        onClick={async () => {
          const update = Toast({
            type: "loading",
            message: "正在请求...",
            forbidClick: true,
            duration: 0,
          });
          const res = await yopModel.wallet();
          if (res?.code === 200) {
            if (!res?.data?.result?.url) {
              Toast(res?.data?.error?.message);
              return;
            }
            if (!!user?.walletUserNo) {
              state.url = res?.data?.result?.url;
            } else {
              window.location.href = res?.data?.result?.url;
            }
          }
          setTimeout(() => {
            update.clear();
          }, 2000);
        }}
      >
        <div className={styles.left}>
          <Image className={styles.image} src={"/images/wallet/wallet-1.png"} />
          <div className={styles.info}>
            <div className={styles.title}>易宝钱包</div>
            <div className={styles.desc}>合规稳定的钱包</div>
          </div>
        </div>
        <div className={styles.right}>
          <Button className={styles.btn} size="small" round>
            进入钱包
          </Button>
        </div>
      </div>

      <div
        className={`${styles.item} ${styles.wallet2}`}
        onClick={async () => {
          Toast("敬请期待");
          return;
          // const update = Toast({
          //   type: "loading",
          //   message: "正在请求...",
          //   forbidClick: true,
          //   duration: 0,
          // });
          // const res = await huifuModel.wallet();
          // if (res?.code === 200) {
          //   if (!res?.data?.url) {
          //     Toast("获取钱包链接失败");
          //     return;
          //   }
          //   window.location.href = res?.data?.url;
          // }
          // setTimeout(() => {
          //   update.clear();
          // }, 2000);
        }}
      >
        <div className={styles.left}>
          <Image className={styles.image} src={"/images/wallet/wallet-2.png"} />
          <div className={styles.info}>
            <div className={styles.title}>汇付钱包</div>
            <div className={styles.desc}>快捷高效的钱包</div>
          </div>
        </div>
        <div className={styles.right}>
          <Button className={styles.btn} size="small" round>
            敬请期待
          </Button>
        </div>
      </div>
      <div
        className={`${styles.item} ${styles.wallet3}`}
        onClick={async () => {
          Toast("敬请期待");
          return;
        }}
      >
        <div className={styles.left}>
          <Image className={styles.image} src={"/images/wallet/wallet-3.png"} />
          <div className={styles.info}>
            <div className={styles.title}>新生钱包</div>
            <div className={styles.desc}>极速便利的钱包</div>
          </div>
        </div>
        <div className={styles.right}>
          <Button className={styles.btn} size="small" round>
            敬请期待
          </Button>
        </div>
      </div>
      <Popup className={styles.webview} visible={!!state.url} position="right">
        <NavBar
          title="我的钱包"
          rightText={<a onClick={() => (state.url = null)}>关闭</a>}
        />
        {state.url && (
          <iframe
            className={styles.iframe}
            src={state.url}
            sandbox="allow-same-origin allow-scripts"
            allow="camera *; microphone *"
          />
        )}
      </Popup>
    </div>
  );
};
